import React, { useState } from 'react';
import logo from '../img/logo-white.png';
import { FaBars } from 'react-icons/fa';

function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="fixed w-full bg-red-400 shadow-md py-4 z-10">
      <div className="flex items-center justify-between px-6">
        {/* <p>Saya perlukan sponsor</p>
        <a href='https://wa.me/60102701320?text=Sponsor%20Jemputlah' className='text-center px-3 py-1 bg-green-500 rounded-full text-white font-bold'>Klik Sini</a>
         */}
        <div></div>
        <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
        <div className="">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <FaBars />
          </button>
        </div>
      </div>
      <div className={`bg-red-400 shadow-md absolute w-full top-16 transition-all duration-300 ${isMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
        {/* Menu items */}
        <a href="dashboard" className="text-center block px-4 py-4 hover:bg-red-500 text-white">Log Masuk</a>
        <a href="sumbangan" className="text-center block px-4 py-4 hover:bg-red-500 text-white">Sumbangan</a>
      </div>
    </header>
  );
}

export default NavBar;
